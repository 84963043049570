import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/header';
import TimeAgo from 'react-timeago';

function priceLevelTooltip(number) {
  const level = [
    'Free',
    'Inexpensive',
    'Moderately Expensive',
    'Expensive',
    'Very Expensive',
  ];
  return level[number];
}

function ratingTooltip(number) {
  const level = {
    1: 'Hated it',
    2: 'Disliked it',
    3: "It's okay",
    4: 'Liked it',
    5: 'Loved it',
  };
  return level[number];
}

class PriceLevel extends React.Component {
  render() {
    return (
      <span className="price-level">
        Price level:{' '}
        <span className="italic">{priceLevelTooltip(this.props.number)}</span>
        <span itemProp="priceRange" style={{ display: 'none' }}>
          {this.props.number}
        </span>
      </span>
    );
  }
}

class Rating extends React.Component {
  render() {
    return (
      <p
        className="text-black font-bold my-2"
        title={ratingTooltip(this.props.number)}
      >
        Rating: <span itemProp="ratingValue">{this.props.number}</span>
      </p>
    );
  }
}

class Page extends React.Component {
  render() {
    const data = this.props.data;
    let cityTitle = data.airtable.data.City;

    return (
      <Layout>
        <Header
          crumbs={[
            {
              title: data.site.siteMetadata.title,
              location: '/',
              active: false,
            },
            {
              title: data.airtable.data.Section[0].data.Title,
              location: data.airtable.data.Section[0].data.Path,
              active: false,
            },
            {
              title: data.airtable.data.Title,
              location: null,
              active: true,
            },
          ]}
        />
        <div itemScope="" itemType="http://schema.org/Review">
          <div
            itemProp="itemReviewed"
            itemScope=""
            itemType={data.airtable.data.SchemaName}
          >
            <h2 className="text-4xl text-black font-bold my-2">
              <span itemProp="name">{data.airtable.data.Title}</span> –{' '}
              <small className="whitespace-no-wrap">{cityTitle}</small>
            </h2>

            <div
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
              className="my-2"
            >
              <address>
                <a
                  href={data.airtable.data.Url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span itemProp="streetAddress">
                    {data.airtable.data.StreetAddress}
                  </span>
                  ,
                  <span itemProp="addressLocality">
                    {data.airtable.data.City}
                  </span>
                  ,
                  <span itemProp="addressCountry">
                    {data.airtable.data.Country}
                  </span>
                </a>
              </address>
            </div>

            <p className="text-sm">
              <PriceLevel number={data.airtable.data.PriceRange} />
              <br />
              <a
                href={data.airtable.data.Website}
                target="_blank"
                rel="noopener noreferrer"
                itemProp="url"
                className="underline"
              >
                Website
              </a>
            </p>
          </div>
          <div
            itemProp="reviewRating"
            itemScope=""
            itemType="http://schema.org/Rating"
            className="text-black my-2"
          >
            <Rating number={data.airtable.data.Rating} />
            <div
              className="text-black font-bold my-8"
              itemProp="description"
              dangerouslySetInnerHTML={{
                __html: data.airtable.data.Body.childMarkdownRemark.html,
              }}
            />
          </div>

          <p className="text-sm">
            <span
              itemProp="author"
              itemScope=""
              itemType="http://schema.org/Person"
            >
              <span>
                Reviewed by: <span itemProp="name">Simon</span>
              </span>
            </span>
            <span>, </span>
            <TimeAgo itemProp="dateCreated" date={data.airtable.data.Created} />
          </p>
          <footer>
            <p className="text-xs">
              Published:{' '}
              <TimeAgo
                itemProp="datePublished"
                date={data.airtable.data.Published}
              />
              <br />
              Updated:{' '}
              <TimeAgo
                itemProp="dateModified"
                date={data.airtable.data.Modified}
              />
            </p>
          </footer>
        </div>
      </Layout>
    );
  }
}

export default Page;

// export default ({ data }) => (
//   <Layout>
//     <Header
//       crumbs={[
//         {
//           title: data.site.siteMetadata.title,
//           location: '/',
//           active: false,
//         },
//         {
//           title: data.airtable.data.Section[0].data.Title,
//           location: data.airtable.data.Section[0].data.Path,
//           active: false,
//         },
//         {
//           title: data.airtable.data.Title,
//           location: null,
//           active: true,
//         },
//       ]}
//     />
//     <div itemScope="" itemType="http://schema.org/Review">
//       <div
//         itemProp="itemReviewed"
//         itemScope=""
//         itemType={data.airtable.data.SchemaName}
//       >
//         <h2 className="text-4xl text-black font-bold my-2">
//           <span itemProp="name">{data.airtable.data.Title}</span> –{' '}
//           <small>{data.airtable.data.City}</small>
//         </h2>

//         <div
//           itemProp="address"
//           itemScope
//           itemType="http://schema.org/PostalAddress"
//           className="my-2"
//         >
//           <address>
//             <a
//               href={data.airtable.data.Url}
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <span itemProp="streetAddress">
//                 {data.airtable.data.StreetAddress}
//               </span>
//               ,<span itemProp="addressLocality">{data.airtable.data.City}</span>
//               ,
//               <span itemProp="addressCountry">
//                 {data.airtable.data.Country}
//               </span>
//             </a>
//           </address>
//         </div>

//         <p className="text-sm">
//           <PriceLevel number={data.airtable.data.PriceRange} />
//           <br />
//           <a
//             href={data.airtable.data.Website}
//             target="_blank"
//             rel="noopener noreferrer"
//             itemProp="url"
//             className="underline"
//           >
//             Website
//           </a>
//         </p>
//       </div>
//       <div
//         itemProp="reviewRating"
//         itemScope=""
//         itemType="http://schema.org/Rating"
//         className="text-black my-2"
//       >
//         <Rating number={data.airtable.data.Rating} />
//         <div
//           className="text-black font-bold my-8"
//           itemProp="description"
//           dangerouslySetInnerHTML={{
//             __html: data.airtable.data.Body.childMarkdownRemark.html,
//           }}
//         />
//       </div>

//       <p className="text-sm">
//         <span
//           itemProp="author"
//           itemScope=""
//           itemType="http://schema.org/Person"
//         >
//           <span>
//             Reviewed by: <span itemProp="name">Simon</span>
//           </span>
//         </span>
//         <span>, </span>
//         <TimeAgo itemProp="dateCreated" date={data.airtable.data.Created} />
//       </p>
//       <footer>
//         <p className="text-xs">
//           Published:{' '}
//           <TimeAgo
//             itemProp="datePublished"
//             date={data.airtable.data.Published}
//           />
//           <br />
//           Updated:{' '}
//           <TimeAgo itemProp="dateModified" date={data.airtable.data.Modified} />
//         </p>
//       </footer>
//     </div>
//   </Layout>
// );

export const query = graphql`
  query GetPage($Path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    airtable(table: { eq: "Pages" }, data: { Path: { eq: $Path } }) {
      data {
        Title
        Body {
          childMarkdownRemark {
            html
          }
        }
        Section {
          data {
            Title
            Path
          }
        }

        SchemaName
        PriceRange
        Rating

        FormattedAddress
        StreetAddress
        City
        Country

        Url
        Website

        Created
        Modified
        Published
      }
    }
  }
`;
